import  React from 'react'
import {useState, useCallback} from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


const Buttons = (props) => {
    return (
      <div>
        <div className="center-block text-center col-md-12 text-center" style={{marginTop:'10px', marginBottom:'20px'}}>
      <Link to="/incident" style={{marginTop:'10px', marginBottom:'5px'}} className="btn text-bold btn-space btn-danger btn-lg py-lg-3 px-lg-5" data-toggle="scroll-link" data-animate="fadeIn" data-animate-delay="0.1">Get Help Now!</Link>
      <Link to="/drivers" style={{marginTop:'10px', marginBottom:'5px'}} data-toggle="tooltip" data-placement="left" title="Do you need roadside assistance?" className="btn btn-space btn-secondary btn-md py-lg-3 px-lg-5" data-toggle="scroll-link" data-animate="fadeIn" data-animate-delay="0.1">Free Membership</Link>
      <Link to="/providers" style={{marginTop:'10px', marginBottom:'5px'}} data-toggle="tooltip" data-placement="right" title="Do you need roadside assistance?" className="btn btn-space btn-success btn-md py-lg-3 px-lg-5" data-toggle="scroll-link" data-animate="fadeIn" data-animate-delay="0.1">Service Providers</Link>
	</div>
    <div class="row">
      <div class="col-md-6 mx-auto" style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}>
        <p className="text-justify-center" style={{margin:30}}>We are a free-to-use communication platform for our Getresqued members and roadside assistance service providers. Join Free Membership and receive immediate roadside assistance using our app. Or join as a Service Provider to offer roadside services for our members.</p>
      </div>
      </div> 
  </div>);
}

export default Buttons;
