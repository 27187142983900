import React from 'react'
import ReactDom from 'react-dom'
import { Link } from "react-router-dom"
import {Helmet} from "react-helmet";

const About = () => {
  return (
<div className="container">
          <Helmet>
      		<title>Are you broken down on the road and need Roadside Assistance?</title>
			<meta name="keywords" content="Roadside assistance for Motorist when broken down on the road"/>
    		<meta name="description" content="We are here to provide roadside assistance when brokne down on the road."/>
            <link rel="canonical" href="https://getresqued.com/about" />
    	  </Helmet>
          <div>
            <hr className="hr-lg mt-0 mb-3 w-10 mx-auto hr-primary" />
            <h1 className="text-center text-uppercase  my-0">
              We are <span className="text-shadow">GetResqued</span>
            </h1>
            <h5 className="text-center font-weight-light mt-2 mb-0 text-muted">
              Your personal dispatcher
            </h5>
            <hr className="mb-5 w-50 mx-auto" />
          </div>
          <div className="row">
            <div className="col-md-6"  data-animate-offset="100%">
              <span className="lead">
              <h4 className="mb-1">Why GetResqued?</h4></span>
              <p class="text-left">With todays advanced technological communication platforms, your roadside assistance services should be instant and seamless. Our Mobile App and its platform provide drivers across the nation real-time responses from nearby Roadside Service providers. </p>
			  <p class="text-left">We began our journey by first collecting business cases from both consumers and service providers nationwide. What we discovered is that our competitors (Roadside Motor Clubs) did not utilize the latest technology. As a result, most Motor Clubs will not meet consumer response time leading to extensive wait time when service is requested.</p>
              
              <p className="text-left">Who we are is a real-time communication platform for drivers in need of immediate roadside assistance and available service providers. The Getresqued Platform connects the drivers/consumers to the nearby Roadside service providers in real-time.</p> 

 <p class="text-left">We do not route drivers/consumers through a comprehensive customer service process. Instead drivers/consumers are connected to available service providers nearby instantly by using the Getresqued mobile app.</p>
               <p class="text-left">We are excited to offer this incredibly easy to use mobile app to both drivers and service providers. And we are especially pleased with the feedback received thus far with overwhelming excitement. <b>The Getresqued app is free to install and free to use.</b> </p>
            </div>
            <div className="col-md-6"  data-animate-offset="100%">
              {/*  List Items  */ }
              <ul className="fa-ul list-unstyled mt-4 mt-md-0">
                <li className="mb-4">
                  <i className="fa-li fa fa-check text-primary"></i>
                  <h4 className="mb-1 text-left" >Driver Creates a Request</h4>
                  <p class="text-left">The GetResqued App allows drivers to create and submit a service request for the following needs</p>
                  <ul class="text=left">
                    <li class="text-left"><Link to="/towing">Towing</Link></li>
                    <li class="text-left"><Link to="/out-of-gas">Out of Gas</Link></li>
                    <li class="text-left"><Link to="/lockout">Lockout</Link></li>
                    <li class="text-left"><Link to="/flat-tire">Flat Tire</Link></li>
                    <li class="text-left"><Link to="/dead-battery">Dead Battery</Link></li>

                   </ul>
                </li>
                <li className="mb-3">
                  <i className="fa-li fa fa-check text-primary"></i>
                  <h4 className="mb-1 text-left">
                  Service Provider Accepts Request
                  </h4>
                  <p class="text-left"> Service providers/operators in the area receive request notification and can accept or decline it. Once accepted and on way, the driver is notified.</p>
                </li>
                <li>
                  <i className="fa-li fa fa-check text-primary"></i>
                  <h4 className="mb-1 text-left">Rendered Service</h4>
                  <p class="text-left">When service provider completes the requested service he/she waits for another service request notification from area. Operator can also toggle to 'Off-Duty' and not be notified of service request.</p>
                </li>
              </ul>
            </div>
            </div>
          <div class="row text-center p-4 text-center">
              <div class="col col-xs-6 col-md-6">
      <a href='https://play.google.com/store/apps/details?id=net.gemlogic.towapp.truck&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img class="img-fluid pull-right" alt='Get the GetResqued Roadside assistance app on Google Play' src='google-play-badge.png'/></a>
            </div>
            <div class="col col-xs-6 col-md-6">
              <a href='https://apps.apple.com/us/app/getresqued-provider/id1381226806'><img class="img-fluid pull-left" alt='Get the GetResqued Roadside assistance app on Apple Store' src='apple-download.png'/></a>
          </div>
        </div>
   
        </div>)
      }
export default About
