import  React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Buttons from './Buttons'
import { useLocation } from 'react-router-dom'
import { useRouteMatch } from "react-router-dom"


const JumboTron = () => {
  let location = useLocation()
  let match = useRouteMatch("/blog");
  let isTowing = useRouteMatch("/towing");
  let isFlatTire = useRouteMatch("/flat-tire");
  let isOutOfgas = useRouteMatch("/out-of-gas");
  let isLockout = useRouteMatch("/lockout");
  let isDeadBattery = useRouteMatch("/dead-battery");
  let isRoadsideAssistance = useRouteMatch("/roadside-assistance");
  let content = ""
  let sub = ""
  if(isTowing){
    content="Towing"
  } else if(isFlatTire) {
    content = 'Need help with a flat tire?'
    sub = 'Here’s what to do'
  } else if(isOutOfgas){
    content = 'Out of Gas?'
    sub = 'We can help!'
  } else if(isDeadBattery) {
    content = 'Need roadside battery replacement'
    sub = "Here's what to do"
  } else if(isLockout){
    content = 'Need lockout service?'
    sub = 'We can help!'
  }else if(isRoadsideAssistance){
    content = 'Need roadside assistance provider?'
    sub = 'Here’s what to do'
  }
  else {
    content = 'Need Roadside assistance'
    sub = 'GetResqued!'
  }
  console.log("locatiom xxx" + location.pathname)
  console.log("match " + JSON.stringify(match))
  return (
    <>
      <div style={{backgroundSize:'cover'}}>
        <div className="bg-white overlay overlay-primary overlay-op-7 text-center px-3 py-5 py-lg-10 flex-valign jumbotron-fluid  d-none d-sm-block lazyload"
          data-toggle="backstretch"
          style={{backgroundSize:'cover'}}
          data-backstretch-target="self"
          data-backstretch-overlay="false" data-backstretch-imgs="assets/img/backgrounds/man-with-open-arms-saluting-the-nature-picjumbo-com.jpg">
          <div class="row">
            <div class="a2a_kit a2a_kit_size_32  text-center col-md-2">
              <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
              <a class="a2a_button_facebook" href="https://facebook.com/Getresqued"></a>
              <a class="a2a_button_twitter" href="https://twitter.com/GetResqued"></a>
              <a class="a2a_button_facebook_messenger"></a>
              <a class="a2a_button_linkedin"></a>
            </div>
            <div class="col-md-8"> 
              {!match ?
                <h1 className="display-3 text-white text-center ustify-content-center" data-animate-delay="0.01">
                {content} <span className="font-weight-bold">{sub}</span>
                  <h2 className="text-grey font-weight-normal" style={{color:'#fff'}} data-animate-delay="0.1">
                    <span>Towing - Out of gas - Lockout - Flat tire - Dead battery</span>
                  </h2>
                </h1>
                  :
                <h2 className="display-3 text-white" data-animate-delay="0.01">
                  <span className="font-weight-bold">GetResqued BLOG</span>
                  <h3 className="text-grey font-weight-normal" style={{color:'#fff'}} data-animate-delay="0.1">
                    <span>Travel tips, car care, features articles, and updates for GetResqued</span>
                    </h3>
                    </h2>
                }

              </div>
              
              <div id='signup' className="alert alert-success alert-dismissible collapse" role="alert">
              <h4 className="alert-heading">Thanks for your intereest.!</h4>
              <p>Thank you for your interest in our roadside assistance application. We will send you a link so that you are direct to Google Play or Apple App Store. Please share this with you friends.</p>
            </div>
    </div>
    <div id='no-phone' className="alert alert-warning  alert-dismissible collapse" role="alert">
      <h4 className="alert-heading">Phone Validation</h4>
      <p>You need to provide a valid US phone number. We will send you a sms link which will direct you to the correct store.</p>
    </div>
   </div> 

   </div>
    </>
 );
}

export default JumboTron;
