import 'bootstrap/dist/css/bootstrap.css'
import './test.css'
import '@fortawesome/fontawesome-free'
import $ from 'jquery'
import './custom-style.css'
import './App.css'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga'
import WebFont from 'webfontloader'

 ReactGA.initialize('UA-107802461-2', {
        debug: false,
        gaOptions: { cookieDomain: 'auto' }
      })

   WebFont.load({
     google: {
       families: ['Roboto', 'Slab']
     }
   })
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


