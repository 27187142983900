import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import {Helmet} from "react-helmet";

class Services extends React.Component {
    render() {
      return (
        <>
        <Helmet>
      		<title>Are you broken down on the road and need Roadside Assistance?</title>
			<meta name="keywords" content="Roadside assistance for Motorist when broken down on the road"/>
    		<meta name="description" content="We are here to provide roadside assistance when broken down on the road."/>
            <link rel="canonical" href="https://getresqued.com/services" />
    	  </Helmet>

       <div id="features" className="container py-4 py-lg-6">
        <div>
          <hr className="hr-lg mt-0 mb-3 w-10 mx-auto hr-primary" />
          <h1 className="text-center text-uppercase font-weight-bold my-0">
            Roadside assistance Services
          </h1>
          <h5 className="text-center font-weight-light mt-2 mb-0 text-muted">
            Here are our services, if you need something else just ask!
          </h5>
          <hr className="mb-5 w-50 mx-auto" />
        </div>
        
        <div className="row text-center center-block">
          <div className="col-lg-4 py-2">
            <Link to="/towing">
            <img src="towing.svg" alt="Are you in need of towing or roadside assistance?" className="img-thumbnail" style={{height:85, height:85, border:0}}/>
            <h4 className="mt-2">
              Towing
            </h4>
            </Link>
            <p>GetResqued mobile app was developed to fit onto any mobile device.</p>
          </div>
          <div className="col-lg-4 py-2">
            <Link to="/dead-battery">
            <img src="battery.png" alt="Do you need a jump start or have a dead battery?" className="img-thumbnail" style={{height:85, height:85, border:0}}/>
            <h4 className="mt-2">
              Jump Start
            </h4>
          </Link>
            <p>Contact us for any technical matters, we will be happy to assist you.</p>
          </div>
          <div className="col-lg-4 py-2">
            <Link to="/lockout">
            <img src="locked-car.svg" alt="Did you lock you keys in your car?" className="img-thumbnail" style={{height:85, height:85, border:0}}/>
            <h4 className="mt-2">
              Lockout
            </h4>
          </Link>
            <p>Once you download the GetResqued app, upgrade will be automatically updated while you are asleep.</p>
          </div>
          <div className="col-lg-4 py-2">
            <Link to="/flat-tire">
              <img src="flat_tire.png" alt="Did you get a flat tire that we can change tire for you?" className="img-thumbnail lazyload" style={{height:85, height:85, border:0}}/>
            <h4 className="mt-2">
              Flat tire
            </h4>
          </Link>
            <p>Both driver and operator (Service Providers) are monitored and tracked when service is submitted and accepted. </p>
          </div>

          <div className="col-lg-4 py-2">
            <Link to="/out-of-gas">
            <img src="gas_circle.svg" alt="Did your car run out of gas?" style={{width:85, height:85, border:0}} className="fas img-thumbnail"/>
            <h4 className="mt-2">
              Out of Gas
            </h4>
          </Link>
            <p>Make all your trips safe and fun when you join our GetResqued network!</p>
          </div>

          <div className="col-lg-4 py-2">
            <Link to="/roadside-assistance">
            <img src="roadside-service.png" alt="Did your car or truck brakdown and need service or roadside help?" style={{width:85, height:85, border:0}} className="fas img-thumbnail"/>
            <h4 className="mt-2">
              Roadside Service
            </h4>
          </Link>
            <p>Make all your trips safe and fun when you join our GetResqued network!</p>
          </div>
          
        </div>
      </div>
      </> 
);
    }
}
export default Services;
