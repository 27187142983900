import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "./Client.js";
import {Helmet} from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function AllPosts() {
  const [allPostsData, setAllPosts] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
        title,
        slug,
        mainImage{
          asset->{
          _id,
          url
        }
      }
    }`
      )
      .then((data) => setAllPosts(data))
      .catch("error " + console.error);
  }, []);

  return (
    <div>
      <Helmet>

            <link rel="canonical" href="https://getresqued.com/" />
            <title data-react-helmet="true">GetResqued | Your Immediate Roadside Assistance Provider</title>
            <meta name="description" content="Do you require immediate towing, roadside assistnace, flat tire or have locked keys in you car? Let us help you with find Service providers for your roadside help." data-react-helmet="true"/>
	        <meta name="keywords" content="emergency Roadside Motorist services, emergency road service, Motorist Car trouble breakdown assistance " data-react-helmet="true"/>
    	  </Helmet>

      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 mb-4 justify-center mx-auto d-flex justify-content-center col-12" style={{marginBottom:'10px', backgroundColor:'#f0f0f0'}}>
        {allPostsData &&
          allPostsData.map((post, index) => (
            <Link to={"/blog/" + post.slug.current} key={post.slug.current}>
            <div class="card card-default m-4">
            <LazyLoadImage class="card-img-top lazyload" src={post.mainImage && post.mainImage.asset.url} alt="Card image" style={{padding:'20px', height:'150', width:'150'}}/>
              <div class="card-body">
                <h5 class="card-title">{post.title}</h5>
                <h6 class="card-subtitle mb-2 text-muted">&nbsp;</h6>
                  <p class="card-text"></p>
              </div>
              </div>
              </Link>
            ))}
      </div>
    </div>
  );
}
