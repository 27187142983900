import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery'
import Services from './Services'
import About from './About'
import Buttons from './Buttons'
import Blogs from './HomeBlogs'


class Body extends React.Component {
  render() {
return (<>
<Buttons/>
{/** body className="page page-onepager header-compact-sticky page-index-onepager-bg-slideshow navbar-layout-default">*/ }
  
  {/*  @plugin: page loading indicator, delete to remove loader  */ }
  <div className="page-loader" data-toggle="page-loader"></div>
  
  <a href="#content" id="top" className="sr-only">Skip to content</a>
  
  {/*  ======== @Region: #header ========  */ }
  {/*  react componenet header  */ }
  {/*  ======== @Region: #highlighted ========  */ }
  {/*  Image Banner: Use the the data-bg-img functionality to create a simple image banner Use spacer classes to make the banner larger with padding, allows for easy responsive changes too see: elements-ctas.htm To make the banner full height add the attributes: data-toggle="full-height" data-offset="#header"  */ }
      <About />
      <Services />
      <Blogs/>
  {/*  Features  */ }
  <div id='services'/>
    {/*  Modal  */ }
    <div className="modal fade" id="videoModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
            {/*  16:9 aspect ratio  */ }
            <div className="embed-responsive embed-responsive-16by9">
              <iframe id="video" width="100%" height="350" src=""></iframe>
              {/* iframe id="video" width="560" height="315" src="https://www.youtube.com/embed/3XYYQUjV-Nc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe */ }
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*  ======== @Region: #content ========  */ }
    <div id="content" className="p-0">
      {/*  About  */}
      <div id="about" className="py-3 pt-lg-6 pb-lg-0">
                {/*  Why AppStrap  */}
        <div className="bg-inverse mt-3 mt-lg-6">
          <div className="container-fluid">
            <div className="row">
            </div>
          </div>
        </div>
        <hr className="my-0" />
    </div>
    {/*  Hiring MDR block  */ }
    {/*  Features  */ }
    {/* Pricing Table */}
    <div id="pricing" className="bg-faded py-3 py-lg-6">
      <div className="container">
      {/*  Plan features  */ }
      <div className="bg-grey bg-shadow py-3 mt-4 text-center" data-animate="fadeInUp" data-animate-duration="1.2" data-animate-offset="100%">
        <h4 className="d-inline-block pr-1 my-0">
        <span>What's not Free <span className="font-weight-normal text-muted"></span>:</span>
        </h4>
        <p className="d-inline-block text-muted my-0">Service rendored to driver is not free <span className="text-primary font-weight-bold">/</span> Driver and operator should always discuss and confirm service fee before confirming transaction. <span className="text-primary font-weight-bold"></span></p> <p>Drive Safe & NEVER Text and Drive!<span className="text-primary font-weight-bold"></span></p>
      </div>
    </div>
  </div>
  {/*  Video background MDR - added background image playing  */ }
</div>
{/* Customer testimonial */ }
{/*  Contact form  */ }
</>

);
}
componentDidMount() {
      let $videoSrc = "https://www.youtube.com/embed/3XYYQUjV-Nc?rel=0&autostart=1&html5=1";
      {/* when the modal is opened autoplay it */}
      $('#videoModal').on('shown.bs.modal', function (e) {
        {/** set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        jQuery("#video").attr('src',$videoSrc + "?rel=0&amp;showinfo=0&amp;modestbranding=1&amp;autoplay=1" ); */}
        $("#video").attr('src',$videoSrc );
      });
      
      {/* stop playing the youtube video when I close the modal */}
      $('#videoModal').on('hide.bs.modal', function (e) {
        {/* a poor man's stop video */}
        $("#video").attr('src',$videoSrc);
      })

    $("#contact-form").submit(function(event) {
      var data = {};
      data.name = $('#contact-name').val();
      data.email = $('#contact-email').val();
      data.comments = $('#contact-message').val();
      $.ajax({
        type: 'POST',
        url: 'sendmail',
        data:JSON.stringify(data),
        contentType: 'application/json',
        success: function(data) {
          $('#contactus').show();
          $('#contact-name').val('');
          $('#contact-email').val('');
          $('#contact-message').val('');
          console.log('success');
          console.log(data);
        }
      });
    });
    $("#subscribe-button").click(function() {
      var data = {};
      data.phone= $('#get-app').val();
        if (data.phone === ''){
          $('#no-phone').show();
        } else {
          console.log("phone " + $('get-app').val());
          console.log("called ajax" + JSON.stringify(data));
          $.ajax({
            type: 'POST',
            url: 'get-app',
            data:JSON.stringify(data),
            contentType: 'application/json',
            success: function(data) {
              $('#signup').show();
              $('#subscribe-email').val('');
              console.log('success');
              console.log(data);
              
            },
            error: function (data) {
              console.log(JSON.stringify(data));  
            },
            complete: function(response, textStatus) {
              console.log("Hey: " + textStatus);
            }
          });
        }
    });

    $("#callback-button").click(function() {
      var data = {};
      data.phone_number= $('#callback-number').val();
      console.log("called ajax");
      $.ajax({
        type: 'POST',
        url: 'callback',
        data:JSON.stringify(data),
        contentType: 'application/json',
        success: function(data) {
          $('#request-callback').show();
          $('#callback-number').val('');
          console.log('success');
          console.log(data);
        },
        error: function (data) {
            console.log(JSON.stringify(data));
        },
        complete: function(response, textStatus) {
          console.log("Hey: " + textStatus);
        }
      });
    });
}
}
export default Body
