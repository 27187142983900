import React, { Suspense, lazy, Component } from 'react';
import logo from './logo.svg';
import { Router as Router, BrowserRouter, Route, Link, withRouter, Redirect, Switch, useLocation} from "react-router-dom"
import { HashRouter } from 'react-router-dom'
import './App.css';
import Body from './Body'
import JumboTron from './JumboTron'
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga'
import Helmet from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async';
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
const Greeting = lazy(() => import('./Greeting'))
const Header = lazy(() => import('./Header'))
const Footer2 = lazy(() => import('./Footer2'))
const Towing = lazy(() => import('./Towing'))
const JumpStart = lazy(() => import('./JumpStart'))
const Lockout = lazy(() => import('./Lockout'))
const FlatTire = lazy(() => import('./FlatTire'))
const OutOfGas = lazy(() => import('./OutOfGas'))
const RoadsideAssistance = lazy(() => import('./RoadsideAssistance'))
const ContactUs = lazy(() => import('./ContactUs'))
const Services = lazy(() => import('./Services'))
const About = lazy(() => import('./About'))
const Provider = lazy(() => import('./Provider'))
const Driver = lazy(() => import('./Driver'))
//const Incident = lazy(() => import('./Incident'))
const IncidentWrapper = lazy(() => import('./IncidentWrapper'))
const Incident = lazy(() => import('./IncidentWrapper'))
const Privacy = lazy(() => import('./Privacy'))
const Terms = lazy(() => import('./Terms'))
const Copyright = lazy(() => import('./Copyright'))
const Download = lazy(() => import('./Download'))
const OnePost = lazy(() => import('./OnePost'))
const OnePostRoot = lazy(() => import('./OnePostRoot'))
const AllPosts = lazy(() => import('./AllPosts'))
const NotFound = lazy(() => import('./NotFound'))


export const history = createBrowserHistory();
history.listen((location, action) => {
  ReactGA.pageview(window.location.pathname)
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function(registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function(err) {
      console.log("Service worker registration failed, error:", err);
    });
}

class App extends Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }
     constructor(props){
      super(props);
    }

    componentDidMount() {
      ReactGA.pageview(window.location.pathname)
    }

    render() {
      const { match, location, history } = this.props;
      return (
        <BrowserRouter history={history}>
        <Suspense fallback={<div>Loading...</div>}>
        	<div className="App">
		<HelmetProvider>
		<Helmet>
        <title>Emergency Road Service, Motorist Car Trouble Breakdown Assistance | Emergency Motorist Services - GETRESQUED</title>
        <meta name="description" content="Do you require immediate towing, roadside assistnace, flat tire or have locked keys in you car? Let us help you with find Service providers for your roadside help."/>
	    <meta name="keywords" content="emergency Roadside Motorist services, emergency road service, Motorist Car trouble breakdown assistance "/>
    	</Helmet>
		</HelmetProvider>
          <Header />
          <JumboTron disableJumboTron={false}/>
          <Switch>
          <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
          <Route path="/about" component={About} />
          <Route path="/towing" exact  component={Towing} />
          <Route path="/dead-battery" component={JumpStart} />
          <Route path="/lockout" component={Lockout} />
          <Route path="/flat-tire" component={FlatTire} />
          <Route path="/out-of-gas" component={OutOfGas}  disableJumboTron={true}/>
          <Redirect from="/out-of-ga" to="/blog" />
          <Route path="/roadside-assistance" component={RoadsideAssistance} />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/services" component={Services} />
          <Route path="/providers" component={Provider} />
          <Route path="/drivers" component={Driver} />
          <Route path="/incident" component={Incident} />
          <Redirect from="/blog/why-is-my-car-overheating" to="/blog/car-overheating" exact />
          <Redirect from="/blog/here-s-what-you-need-to-do-when-you-run-out-of-gas" to="/blog/here-is-what-you-need-to-do-when-you-run-out-of-gas" exact/>
          <Redirect from="/blog/getresqued.com" to="/blog" exact />
          <Redirect from="/blog/getresqued.com/*" to="/blog" />
          <Route component={AllPosts} path="/blog" exact disableJumboTron={true}/>
          <Route component={OnePost} path="/blog/:slug" />
          <Route path="/" exact component={Body} />
          <Route path="/privacy" exact component={Privacy} />
          <Redirect from="/legal/privacy.html" to="/blog"/>
          <Route path="/terms" exact component={Terms} />
          <Route path="/copyright" exact component={Copyright} />
          <Redirect from="/legal/copyright" to="/blog" />
          <Redirect from="/device" to="/blog" />
          <Redirect from="/index.htm" to="/blog" />
          <Redirect from="/legal/privacy" to="/blog" />
          <Redirect from="/login.htm" to="/blog" />
          <Redirect from="/PP.html" to="/blog" />
          <Redirect from="/roadside-assistance-appa" to="/blog" />
          <Route path="/downloads" component={Download} />
          <Route path="/roadside-assistance-app" component={Download} />
          <Route path="/best-roadside-assistance-provider" component={OnePostRoot} />
          <Route path="*" component={AllPosts} disableJumboTron={true}/>
          </Switch>
          <Footer2 />
          </div>
          </Suspense>
        </BrowserRouter>
      )}
}

export default App
